import logo from './logo.svg';
import Adsense from './Adsense';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <Adsense/>
        <img src={logo} className="App-logo" alt="logo" />
        <p>
          Hashmap.Shop
        </p>
       
      </header>
    </div>
  );
}

export default App;
