import React, { useEffect } from 'react';

const AdSense = () => {
 
  return (
    <div>
    <p>Adsense</p>
      <amp-ad width="100vw" height="320"
         type="adsense"
         data-ad-client="ca-pub-7920548736987408"
         data-ad-slot="2384889135"
         data-auto-format="mcrspv"
         data-full-width="">
      <div overflow=""></div>
    </amp-ad>
    </div>
  );
};

export default AdSense;
